import React from 'react';
import ReactDOM from 'react-dom';

import events from 'events.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

import {
    PracticeStoreContextV2,
    ProfileStoreContextV2,
} from 'core/stores/RootStore.js';
import ProfileAppV2 from 'professional/components/v2/ProfileAppV2.js';

(async function () {
    await events.ready();

    const profileSection = document.querySelector('#professional-profile');
    const {
        adData,
        bio,
        claimUrl,
        specialties,
        url,
        userId,
        totalContributions,
        totalAgrees,
        totalThanks,
        profileId,
    } = snakeToCamelObjectKeys(JSON.parse(profileSection.dataset.profile));

    const promotedGapSchemes = profileSection.dataset.promotedGapSchemes;
    const promotions = profileSection.dataset.promotions;
    const props = {
        adData,
        bio,
        claimUrl,
        globalPromotedGapSchemes: JSON.parse(promotedGapSchemes),
        url,
        userId,
        totalContributions,
        totalAgrees,
        totalThanks,
        specialties, // API does not return `displayName` attribute needed for overview
        profileId,
    };
    if (promotions?.length) {
        props.promotions = JSON.parse(promotions).map((promotion) =>
            snakeToCamelObjectKeys(promotion),
        );
    }

    ReactDOM.render(
        <PracticeStoreContextV2.Provider value={ProfileStoreContextV2}>
            <ProfileAppV2 {...props} />
        </PracticeStoreContextV2.Provider>,
        profileSection,
    );
})();

// TODO
// Consolidate with PracticeAppV2

import {observer} from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import query from 'query.js';
import analytics from 'analytics.js';

import Ad from 'core/components/Ad.js';
import {PracticeStoreContextV2} from 'core/stores/RootStore.js';
import PracticeContentV2 from 'practices/components/v2/PracticeContentV2.js';
import PracticeHeaderV2 from 'practices/components/v2/PracticeHeaderV2.js';

export default
@observer
class PracticeAppV2 extends React.Component {
    static contextType = PracticeStoreContextV2;
    static propTypes = {
        adData: PropTypes.shape({
            targeting: PropTypes.object.isRequired,
        }),
        url: PropTypes.string.isRequired,
        specialties: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.string.isRequired,
                displayName: PropTypes.string.isRequired,
            }),
        ),
        totalAgrees: PropTypes.number,
        totalContributions: PropTypes.number,
        totalThanks: PropTypes.number,
        url: PropTypes.string.isRequired,
        userId: PropTypes.number,
    };

    adData = {};

    componentDidMount() {
        this.context.updateStore({
            adData: this.props.adData,
            claimUrl:this.props.claimUrl,
            bio: this.props.bio,
            specialties: this.props.specialties,
            totalAgrees: this.props.totalAgrees,
            totalContributions: this.props.totalContributions,
            totalThanks: this.props.totalThanks,
            url: this.props.url,
            userId: this.props.userId,
        });
        this.context.rootStore.setupLogrocket();
        this.practitionerDetailEvent();
    }

    async practitionerDetailEvent() {
        analytics.track('practitionerDetail', {
            practitionerId: this.props.profileId,
        });
    }

    render() {
        const {adData, specialties} = this.props;
        const specialtiesNames =
            specialties &&
            specialties.map((specialty) => specialty.displayName);
        if (adData && adData.targeting) {
            adData.targeting.healthFund = query.parse().health_fund
                ? 'True'
                : 'False';
            adData.targeting.specialty = specialtiesNames;
        }

        const {
            rootStore: {paramStore},
            practiceLoaded,
        } = this.context;
        if (!practiceLoaded) {
            return null;
        }
        return (
            <>
                {adData && (
                    <Ad
                        {...adData}
                        adUnit="header"
                        customClass={'ad-v2-header-desktop'}
                        slotNumber={1}
                    />
                )}
                <PracticeHeaderV2 />
                <PracticeContentV2 />
            </>
        );
    }
}
